<template>
    <div>
		<div class="card border-primary round h-100">
			<div class="card-body p-3">
				<h4 class="text-center"><i class="fa fa-user-circle"></i> &nbsp; Profil Pengguna</h4>
				<hr />
				<ul class="nav nav-pills w-100" id="pills-tab" role="tablist">
					<!-- <li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-50 w-xl-50 px-2 py-1">
						<a @click="to_topPage" class="nav-link border border-info rounded-lg shadow-sm active" data-toggle="tab" href="#transaction-history" role="tab">
							<h6 class="my-1"><i class="fa fa-handshake"></i>&nbsp; Daftar Transaksi</h6>
						</a>
					</li>
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-50 w-xl-50 px-2 py-1">
						<a @click="to_topPage" class="nav-link border border-info rounded-lg shadow-sm" data-toggle="tab" href="#privacy" role="tab">
							<h6 class="my-1"><i class="far fa-user"></i>&nbsp; Data Diri</h6>
						</a>
					</li>
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-50 w-xl-50 px-2 py-1">
						<a @click="to_topPage" class="nav-link border border-info rounded-lg shadow-sm" data-toggle="tab" href="#member-address" role="tab">
							<h6 class="my-1"><i class="far fa-address-card"></i>&nbsp; Daftar Alamat</h6>
						</a>
					</li>
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-50 w-xl-50 px-2 py-1">
						<a @click="to_topPage" class="nav-link border border-info rounded-lg shadow-sm" data-toggle="tab" href="#change-password" role="tab">
							<h6 class="my-1"><i class="fa fa-unlock-alt"></i>&nbsp; Ubah Kata Sandi</h6>
						</a>
					</li>
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-50 w-xl-50 px-2 py-1">
						<a @click="to_topPage" class="nav-link border border-info rounded-lg shadow-sm" data-toggle="tab" href="#bank-account" role="tab">
							<h6 class="my-1"><i class="fa fa-money-check-alt"></i>&nbsp; Rekening Bank</h6>
						</a>
					</li>
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-50 w-xl-50 px-2 py-1">
						<a @click="to_topPage" class="nav-link border border-info rounded-lg shadow-sm" data-toggle="tab" href="#member-company" role="tab">
							<h6 class="my-1"><i class="far fa-building"></i>&nbsp; Perusahaan</h6>
						</a>
					</li> -->
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-25 w-xl-25 px-2 py-1">
						<a @click="to_topPage" class="nav-link border border-info rounded-lg shadow-sm active" data-toggle="tab" href="#privacy" role="tab">
							<i class="far fa-user fa-2x mt-1"></i>
							<h6 class="mb-1 d-flex justify-content-center align-items-center">Data Diri</h6>
						</a>
					</li>
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-25 w-xl-25 px-2 py-1">
						<a @click="to_topPage" class="nav-link border border-info rounded-lg shadow-sm" data-toggle="tab" href="#member-address" role="tab">
							<i class="far fa-address-card fa-2x mt-1"></i>
							<h6 class="mb-1 d-flex justify-content-center align-items-center">Daftar Alamat</h6>
						</a>
					</li>
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-25 w-xl-25 px-2 py-1">
						<a @click="to_topPage" class="nav-link border border-info rounded-lg shadow-sm" data-toggle="tab" href="#change-password" role="tab">
							<i class="fa fa-unlock-alt fa-2x mt-1"></i>
							<h6 class="mb-1 d-flex justify-content-center align-items-center">Ubah Kata Sandi</h6>
						</a>
					</li>
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-25 w-xl-25 px-2 py-1">
						<a @click="to_topPage" class="nav-link border border-info rounded-lg shadow-sm" data-toggle="tab" href="#member-company" role="tab">
							<i class="far fa-building fa-2x mt-1"></i>
							<h6 class="mb-1 d-flex justify-content-center align-items-center">Perusahaan</h6>
						</a>
					</li>
				</ul>
				<div class="tab-content border rounded-lg shadow px-4 py-3 mt-2">
					<!-- <div class="tab-pane fade show active px-3 py-2" id="transaction-history" role="tabpanel">
						<div class="text-center my-3" v-if="isRefresh">
    					  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    					</div>
						<div>
							<div class="row max-content-user" v-if="transaksi.length > 0">
								<div class="col-md-12 mb-3">
									<div class="table-responsive">
										<table class="table table-striped table-bordered text-center">
											<thead>
												<tr>
													<th scope="col">No.</th>
													<th scope="col">Invoice</th>
													<th scope="col">Tanggal</th>
													<th scope="col">Total Pembayaran</th>
													<th scope="col">Status Transaksi</th>
													<th scope="col">Detail</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(trans, index) in transaksi" :key="index">
													<th scope="row">{{ ++index }}</th>
													<td><p class="mb-0">{{ trans.trans_invoice }}</p></td>
													<td><p class="mb-0">{{ trans.trans_date_buy | momentFormatID }}</p></td>
													<td><p class="mb-0">{{ trans.trans_total_payment | formatCurrency('IDR') }}</p></td>
													<td v-if="trans.trans_status == 5"><h6 class="text-success">{{ trans.trans_status_desc }}</h6></td>
													<td v-else><h6 class="text-danger">{{ trans.trans_status_desc }}</h6></td>
													<td>
														<button type="button" class="btn btn-outline-info w-100" data-toggle="modal" data-target="#modal-detail-transaksi" @click="get_detailTransaction(trans.trans_id, trans.trans_status_desc)">Detail &nbsp;<i class="fa fa-search-plus"></i></button>
													</td>
												</tr>
											</tbody>
										</table>
										<div class="modal fade" id="modal-detail-transaksi" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
											<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
												<div class="modal-content">
													<div class="modal-header">
														<h5 class="modal-title" id="exampleModalLongTitle">Detail Pembelian</h5>
														<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
													</div>
													<div class="modal-body px-4">
														<div class="text-center my-3" v-if="is_refreshDetail">
															<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
														</div>
														<div class="container" v-else>
															<div class="row">
																<div class="col-md-12 max-content-user customScroolBar mb-2">
																	<h6>Produk:</h6>
																	<div class="row d-flex align-items-center w-100 border m-0 p-3 round mb-2" v-for="product in detail_barang_transaksi" :key="product.prod_id">
																		<div class="col-md-3 text-center">
																			<img v-lazy="productImage(product.prod_image)" @error="imageLoadError" class="card-img-top w-75" :alt="image" />
																		</div>
																		<div class="col-md-9 text-left">
																			<router-link :to="'/product-detail/'+product.prod_id+'-'+product.prod_name.toLowerCase()" data-dismiss="modal" class="text-decoration-none">
																				<h6 class="card-text text-secondary mb-0">{{ product.prod_name | capitalize }}</h6>
																				<p class="text-muted mb-0">{{ product.prod_summary | str_limit(50) }}</p>
																			</router-link>
																			<div class="d-flex justify-content-start align-content-center mb-1" v-if="product.detail_discount && product.detail_discount != 0">
																				<p class="text-success mb-0">{{ discountedPrice(product.detail_price, product.detail_discount) | formatCurrency('IDR') }}</p>
																				<p class="bg-danger text-white round px-2 ml-3 mb-0">{{ product.detail_discount }}%</p> 
																				<strike class="text-muted pl-1">{{ product.detail_price | formatCurrency('IDR') }}</strike>
																			</div>
																			<p class="text-success mb-0" v-else>{{ product.detail_price | formatCurrency('IDR') }}</p>
																			<p class="text-muted mb-0">Total Pembelian : {{ product.detail_quantity }} pcs</p>
																		</div>
																	</div>
																</div>
																<div class="col-md-12 mb-2">
																	<h6>Informasi Pembelian:</h6>
																	<div class="row d-flex align-items-center w-100 border border-info m-0 p-2 round mb-2">
																		<div class="col-12 table-responsive-sm">
																			<tr>
																				<td class="py-1" width="25%">Status</td>
																				<td class="py-1 px-2">:</td>
																				<td class="py-1 text-info">{{ status_transaksi | capitalize }}</td>
																			</tr>
																			<tr>
																				<td class="py-1" width="25%">Invoice</td>
																				<td class="py-1 px-2">:</td>
																				<td class="py-1">{{ detail_info_transaksi.trans_invoice }}</td>
																			</tr>
																			<tr>
																				<td class="py-1" width="25%">Tanggal Pembelian</td>
																				<td class="py-1 px-2">:</td>
																				<td class="py-1">{{ detail_info_transaksi.trans_date_buy | momentFormatID }}</td>
																			</tr>
																			<tr>
																				<td class="py-1" width="25%">Pengiriman</td>
																				<td class="py-1 px-2">:</td>
																				<td class="py-1">{{ detail_info_transaksi.trans_carrier_code | uppercase }} ({{ detail_info_transaksi.trans_carrier_pc }}) | {{ detail_info_transaksi.trans_carrier_name }} <span v-if="detail_info_transaksi.trans_carrier_etd">| Estimasi sampai: {{ detail_info_transaksi.trans_carrier_etd }} hari</span></td>
																			</tr>
																			<tr v-if="detail_info_transaksi.trans_status > 1">
																				<td class="py-1" width="25%">Total Pembayaran</td>
																				<td class="py-1 px-2">:</td>
																				<td class="py-1 text-success">{{ detail_info_transaksi.trans_total_payment | formatCurrency('IDR') }} <button class="btn btn-success btn-sm disabled ml-2">Lunas! &nbsp;<i class="fa fa-check-circle"></i></button></td>
																			</tr>
																			<tr>
																				<td class="py-1" width="25%">Catatan</td>
																				<td class="py-1 px-2">:</td>
																				<td class="py-1">{{ detail_info_transaksi.trans_note }}</td>
																			</tr>
																		</div>
																	</div>
																</div>
																<div class="col-md-12" v-if="detail_info_transaksi.trans_status <= 1">
																	<h6>Pembayaran</h6>
																	<div class="row d-flex align-items-center w-100 border border-success m-0 p-3 round mb-2">
																		<div class="col-md-8 table-responsive-sm ">
																			<div class="row d-flex align-items-center">
																				<div class="col-md-3">
																					<div class="p-1">
																						<img src="@/assets/images/bank-bca.png" @error="imageLoadError" class="card-img-top w-100 shadow" alt="bank BCA" />
																					</div>
																				</div>
																				<div class="col-md-8">
																					<p class="mb-0">BCA KCP Kelapa Gading</p>
																					<p class="mb-0">7480639477</p>
																					<p class="mb-0">a.n. PT. Putra Perdana Sentosa</p>
																				</div>
																				<div class="col-12">
																					<hr />
																				</div>
																				<div class="col-md-3">
																					<div class="p-1">
																						<img src="@/assets/images/bank-bni.png" @error="imageLoadError" class="card-img-top w-100 shadow" alt="bank BNI" />
																					</div>
																				</div>
																				<div class="col-md-8">
																					<p class="mb-0">BNI</p>
																					<p class="mb-0">7480639470</p>
																					<p class="mb-0">a.n. Putra Perdana Sentosa</p>
																				</div>
																			</div>
																		</div>
																		<div class="col-md-4 d-flex align-items-center text-cnter">
																			<div class="border border-success rounded w-100 p-4 shadow-lg">
																				<h6 class="mb-0 text-muted">Total Pembayaran :</h6>
																				<h5 class="mb-0 text-success">{{ detail_info_transaksi.trans_total_payment | formatCurrency('IDR') }}</h5>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="text-center w-100 px-3 pt-3 pb-0" v-else>
								<h5 class="text-info mb-0">Oops!</h5>
								<p class="text-muted">Belum ada transaksi yang tercatat</p>
								<router-link to="/product-list?type=product&q=Semua Produk" class="btn btn-outline-primary rounded"><p class="mb-0">Ayo Belanja Sekarang!</p></router-link>
							</div>
						</div>
					</div> -->
					<div class="tab-pane fade px-3 py-2 show active" id="privacy" role="tabpanel">
						<div class="text-center my-3" v-if="isRefresh">
    					  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    					</div>
						<div class="row d-flex align-items-center justify-content-center" v-else>
							<div class="col-lg-4 col-10 mb-2">
								<img :src="profileImage(profile.photo)" @error="imageLoadError" :alt="profile.photo" class="rounded-circle shadow border w-100" />
							</div>
							<div class="col-lg-8"><div class="table-responsive">
								<table class="table table-borderless table-striped mb-0">
									<tr>
										<td class="pb-0" width="30%">Username</td>
										<td class="pb-0">:</td>
										<td class="pb-0">{{ profile.username }}</td>
									</tr>
									<tr>
										<td class="pb-0" width="30%">Nama Lengkap</td>
										<td class="pb-0">:</td>
										<td class="pb-0">{{ profile.fullname }}</td>
									</tr>
									<tr>
										<td class="pb-0" width="30%">Email</td>
										<td class="pb-0">:</td>
										<td class="pb-0">{{ profile.email }}</td>
									</tr>
									<tr>
										<td class="pb-0" width="30%">Telepon</td>
										<td class="pb-0">:</td>
										<td class="pb-0">{{ profile.telp }}</td>
									</tr>
									<!-- <tr>
										<td class="pb-0" width="30%">Alamat</td>
										<td class="pb-0">:</td>
										<td class="pb-0">{{ profile.telp }}</td>
									</tr> -->
									<!-- <tr>
										<td class="pb-0" width="30%">Tanggal Daftar</td>
										<td class="pb-0">:</td>
										<td class="pb-0">{{ profile.user_id }}</td>
									</tr> -->
								</table>
							</div>
							</div>
						</div>
						
						<div class="form-group text-center mb-0 mt-4">
							<!-- Button trigger modal -->
							<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#ubah-data-diri-modal">Ubah Data Diri &nbsp; <i class="fa fa-edit"></i></button>
							
							<!-- Modal Ubah -->
							<div class="modal fade" id="ubah-data-diri-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
								<div class="modal-dialog modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<h5 class="modal-title" id="exampleModalLongTitle">Ubah Data Diri</h5>
											<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
										</div>
										<form ref="formUpdateProfile" class="px-3 py-2 text-left" @submit.prevent="updateProfile" enctype='multipart/form-data'>
											<div class="modal-body">
												<div class="mb-2">
													<div v-if="!image_show">
														<label class="text-muted mb-1">Unggah Foto Profil</label>
														<div class="custom-file">
															<input type="file" @change="onFileChange" class="custom-file-input" id="customFile2" accept="image/*" required>
															<label class="custom-file-label" for="customFile2">Pilih Gambar</label>
															<small class="text-info ml-2">*Ukuran gambar maksimal hanya 2 MB.</small>
														</div>
													</div>
													<div class="text-center rounded" style="overflow: hidden;" v-else>
														<div>
															<img :src="profileImage(image_show)" style="width: 50%;" class="rounded-circle shadow border" v-if="first_preview" />
															<img :src="image_show" style="width: 50%;" class="rounded-circle shadow border" v-else />
														</div>
														<button class="btn btn-outline-secondary mt-2 px-3 rounded btn-sm" @click="removeImage">Ubah Foto Profil</button>
													</div>
												</div>
											
												<label class="text-muted mb-1">Username</label>
												<div class="input-group mb-2 mr-sm-2">
													<div class="input-group-prepend">
														<div class="input-group-text bg-white"><i class="fa fa-address-card"></i></div>
													</div>
													<input type="text" class="form-control pr-5" name="username" :value="profile.username" placeholder="Username" required>
												</div>
												
												<label class="text-muted mb-1">Nama Lengkap</label>
												<div class="input-group mb-2 mr-sm-2">
													<div class="input-group-prepend">
														<div class="input-group-text bg-white"><i class="far fa-user"></i></div>
													</div>
													<input type="text" class="form-control pr-5" name="fullname" :value="profile.fullname" placeholder="Nama Lengkap" required>
												</div>
												
												<label class="text-muted mb-1">Telepon</label>
												<div class="input-group mb-2 mr-sm-2">
													<div class="input-group-prepend">
														<div class="input-group-text bg-white"><i class="fa fa-phone-alt"></i></div>
													</div>
													<input type="number" class="form-control pr-5" name="telp" :value="profile.telp" placeholder="Telepon" required>
												</div>
												
											</div>
											<div class="modal-footer">
												<button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
												<button type="submit" class="btn btn-primary">Simpan &nbsp; <i class="fa fa-paper-plane"></i></button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade px-3 py-2" id="member-address" role="tabpanel">
						<div class="text-center my-3" v-if="isRefresh">
    					  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    					</div>
						<div class="table-responsive" v-else>
							<table class="table table-striped mb-0" v-if="address.length > 0">
								<tr class="border-bottom d-flex align-items-center" v-for="(addrs, index) in address" :key="addrs.mem_add_id">
									<td class="border-top-0 border-bottom-0 border-left-0 border-right border-primary" v-if="index == 0">
										<h5 class="text-primary">{{ ++index }}</h5>
									</td>
									<td class="border-top-0 border-bottom-0 border-left-0 border-right" v-else>
										<h5 class="text-muted">{{ ++index }}</h5>
									</td>
									<td class="border-0" width="70%">
										<h6>{{ addrs.mem_add_name | capitalize }}</h6>
										<p class="mb-0 ml-3">{{ addrs.mem_add_recipient_name | capitalize }} ({{ addrs.mem_add_phone }})</p>
										<p class="mb-0 ml-3">{{ addrs.mem_add_propinsi }} | {{ addrs.mem_add_city }} | {{ addrs.mem_add_kecamatan }} | {{ addrs.mem_add_address }} | {{ addrs.mem_add_zipcode }}</p>
									</td>
									<td class="border-0" width="25%">
										<button type="button" class="btn btn-outline-warning m-1" data-toggle="modal" @click="prepare_changeAddress(addrs.mem_add_id, addrs.prov_id, addrs.city_id)"><i class="fa fa-edit"></i></button>
										<!-- Modal Ubah -->
										<div class="modal fade" :id="'update-alamat-modal-'+ addrs.mem_add_id" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
											<div class="modal-dialog modal-dialog-centered" role="document">
												<div class="modal-content">
													<div class="modal-header">
														<h5 class="modal-title" id="exampleModalLongTitle">Ubah Alamat</h5>
														<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
													</div>
													<div class="text-center my-3" v-if="is_refreshDetail">
														<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
													</div>
													<form ref="formUpdateAddress" class="px-3 py-2 text-left" @submit.prevent="updateAddress(addrs.mem_add_id, --index)" v-else>
														<div class="modal-body">
															<label class="text-muted mb-1">Label Alamat</label>
															<div class="input-group mb-2 mr-sm-2">
																<div class="input-group-prepend">
																	<div class="input-group-text bg-white"><i class="far fa-address-card"></i></div>
																</div>
																<input type="text" class="form-control pr-5" placeholder="Rumah, Kantor, Apartemen, Dropship" name="address_name" :value="addrs.mem_add_name" required>
															</div>
															<label class="text-muted mb-1">Penerima</label>
															<div class="input-group mb-2 mr-sm-2">
																<div class="input-group-prepend">
																	<div class="input-group-text bg-white"><i class="fa fa-user"></i></div>
																</div>
																<input type="text" class="form-control pr-5" placeholder="Nama Penerima" name="recipient_name" :value="addrs.mem_add_recipient_name" required>
															</div>
															<label class="text-muted mb-1">Telepon Penerima</label>
															<div class="input-group mb-2 mr-sm-2">
																<div class="input-group-prepend">
																	<div class="input-group-text bg-white"><i class="fa fa-phone-alt"></i></div>
																</div>
																<input type="number" class="form-control pr-5" placeholder="Misal: 081310005877" name="telp" :value="addrs.mem_add_phone" required>
															</div>
															<label class="text-muted mb-1">Alamat</label>
															<div class="input-group mb-2 mr-sm-2">
																<div class="input-group-prepend">
																	<div class="input-group-text bg-white"><i class="fa fa-map-marker-alt"></i></div>
																</div>
																<textarea class="form-control pr-5" placeholder="Nama Jalan, No. Rumah, No. Kompleks atau Nama Gedung, Lantai atau No. Unit, RT/RW" name="address" :value="addrs.mem_add_address" required></textarea>
															</div>
															<label class="text-muted mb-1">Provinsi</label>
															<div class="input-group mb-2 mr-sm-2">
																<div class="input-group-prepend">
																	<div class="input-group-text bg-white"><i class="fa fa-city"></i></div>
																</div>
																<select class="custom-select" name="province" @change="on_changeProvince($event, 2)" required>
																	<template v-for="prov in province_edit" >
																		<option :value="prov.propinsi_id" :key="prov.propinsi_id" v-if="prov.propinsi_id == addrs.prov_id" selected>{{ prov.propinsi_nama }}</option>
																		<option :value="prov.propinsi_id" :key="prov.propinsi_id" v-else>{{ prov.propinsi_nama }}</option>
																	</template>
																</select>
															</div>
															<label class="text-muted mb-1">Kota</label>
															<div class="input-group mb-2 mr-sm-2">
																<div class="input-group-prepend">
																	<div class="input-group-text bg-white"><i class="fa fa-city"></i></div>
																</div>
																<select class="custom-select" name="city" @change="on_changeCity($event, 2)" required>
																	<option value="" selected disabled v-if="is_loadCity">Tunggu...</option>
																	<option value="" selected disabled v-else>Pilih Kota</option>
																	<template v-for="kab in city_edit" >
																		<option :value="kab.kab_id" :key="kab.propinsi_id" v-if="kab.kab_id == addrs.city_id" selected>{{ kab.kab_nama }}</option>
																		<option :value="kab.kab_id" :key="kab.propinsi_id" v-else>{{ kab.kab_nama }}</option>
																	</template>
																</select>
															</div>
															<label class="text-muted mb-1">Kecamatan</label>
															<div class="input-group mb-2 mr-sm-2">
																<div class="input-group-prepend">
																	<div class="input-group-text bg-white"><i class="fa fa-city"></i></div>
																</div>
																<select class="custom-select" name="kecamatan" required>
																	<option value="" selected disabled v-if="is_loadKec">Tunggu...</option>
																	<option value="" selected disabled v-else>Pilih Kecamatan</option>
																	<template v-for="kec in kecamatan_edit" >
																		<option :value="kec.kec_id" :key="kec.kec_id" v-if="kec.kec_id == addrs.kec_id" selected>{{ kec.kec_name }}</option>
																		<option :value="kec.kec_id" :key="kec.kec_id" v-else>{{ kec.kec_name }}</option>
																	</template>
																</select>
															</div>
															<label class="text-muted mb-1">Kode Pos</label>
															<div class="input-group mb-2 mr-sm-2">
																<div class="input-group-prepend">
																	<div class="input-group-text bg-white"><i class="fa fa-mail-bulk"></i></div>
																</div>
																<input type="number" class="form-control pr-5" placeholder="Kode Pos" name="zipcode" :value="addrs.mem_add_zipcode" required>
															</div>
														</div>
														<div class="modal-footer">
															<button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
															<button type="submit" class="btn btn-primary">Simpan &nbsp; <i class="fa fa-paper-plane"></i></button>
														</div>
													</form>
												</div>
											</div>
										</div>
										<button type="button" class="btn btn-outline-danger m-1" @click="deleteAddress(addrs.mem_add_id, addrs.mem_add_name)"><i class="fa fa-trash"></i></button>
									</td>
								</tr>
							</table>
							<div class="text-center w-100 px-3 pt-3 pb-0" v-else>
								<h5 class="text-info mb-0">Oops!</h5>
								<p class="text-muted mb-0">Belum ada alamat yang tercatat</p>
							</div>
						</div>
						<div class="form-group text-center mb-0 mt-4">
							<!-- Button trigger modal -->
							<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#tambah-alamat-modal">Tambah Alamat &nbsp; <i class="fa fa-plus-circle"></i></button>
							<!-- Modal Tambah -->
							<div class="modal fade" id="tambah-alamat-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
								<div class="modal-dialog modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<h5 class="modal-title" id="exampleModalLongTitle">Tambah Alamat</h5>
											<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
										</div>
										<form ref="formAddAddress" class="px-3 py-2 text-left" @submit.prevent="addAddress">
											<div class="modal-body">
												<label class="text-muted mb-1">Label Alamat</label>
												<div class="input-group mb-2 mr-sm-2">
													<div class="input-group-prepend">
														<div class="input-group-text bg-white"><i class="far fa-address-card"></i></div>
													</div>
													<input type="text" class="form-control pr-5" placeholder="Rumah, Kantor, Apartemen, Dropship" name="address_name" required>
												</div>
												<label class="text-muted mb-1">Penerima</label>
												<div class="input-group mb-2 mr-sm-2">
													<div class="input-group-prepend">
														<div class="input-group-text bg-white"><i class="fa fa-user"></i></div>
													</div>
													<input type="text" class="form-control pr-5" placeholder="Nama Penerima" name="recipient_name" required>
												</div>
												<label class="text-muted mb-1">Telepon Penerima</label>
												<div class="input-group mb-2 mr-sm-2">
													<div class="input-group-prepend">
														<div class="input-group-text bg-white"><i class="fa fa-phone-alt"></i></div>
													</div>
													<input type="number" class="form-control pr-5" placeholder="Misal: 081310005877" name="telp" required>
												</div>
												<label class="text-muted mb-1">Alamat</label>
												<div class="input-group mb-2 mr-sm-2">
													<div class="input-group-prepend">
														<div class="input-group-text bg-white"><i class="fa fa-map-marker-alt"></i></div>
													</div>
													<textarea class="form-control pr-5" placeholder="Nama Jalan, No. Rumah, No. Kompleks, Nama Gedung, Lantai atau No. Unit, RT/RW" name="address" required></textarea>
												</div>
												<label class="text-muted mb-1">Provinsi</label>
												<div class="input-group mb-2 mr-sm-2">
													<div class="input-group-prepend">
														<div class="input-group-text bg-white"><i class="fa fa-city"></i></div>
													</div>
													<select class="custom-select" name="province" @change="on_changeProvince($event, 1)" required>
														<option value="" selected disabled>Pilih Provinsi</option>
														<option :value="prov.propinsi_id" v-for="prov in province" :key="prov.propinsi_id">{{ prov.propinsi_nama }}</option>
													</select>
												</div>
												<label class="text-muted mb-1">Kota</label>
												<div class="input-group mb-2 mr-sm-2">
													<div class="input-group-prepend">
														<div class="input-group-text bg-white"><i class="fa fa-city"></i></div>
													</div>
													<select class="custom-select" name="city" @change="on_changeCity($event, 1)" required>
														<option value="" selected disabled v-if="is_loadCity">Tunggu...</option>
														<option value="" selected disabled v-else>Pilih Kota</option>
														<option :value="kab.kab_id" v-for="kab in city" :key="kab.propinsi_id">{{ kab.kab_nama }}</option>
													</select>
												</div>
												<label class="text-muted mb-1">Kecamatan</label>
												<div class="input-group mb-2 mr-sm-2">
													<div class="input-group-prepend">
														<div class="input-group-text bg-white"><i class="fa fa-city"></i></div>
													</div>
													<select class="custom-select" name="kecamatan" required>
														<option value="" selected disabled v-if="is_loadKec">Tunggu...</option>
														<option value="" selected disabled v-else>Pilih Kecamatan</option>
														<option :value="kec.kec_id" v-for="kec in kecamatan" :key="kec.kec_id">{{ kec.kec_name }}</option>
													</select>
												</div>
												<label class="text-muted mb-1">Kode Pos</label>
												<div class="input-group mb-2 mr-sm-2">
													<div class="input-group-prepend">
														<div class="input-group-text bg-white"><i class="fa fa-mail-bulk"></i></div>
													</div>
													<input type="number" class="form-control pr-5" placeholder="Kode Pos" name="zipcode" required>
												</div>
											</div>
											<div class="modal-footer">
												<button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
												<button type="submit" class="btn btn-primary">Simpan &nbsp; <i class="fa fa-paper-plane"></i></button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade px-3 py-2" id="change-password" role="tabpanel">
						<form  @submit.prevent="changePassword">
							<label for="inlineFormInputGroupKataSandi" class="text-muted mb-1">Kata Sandi Saat Ini</label>
							<div class="input-group mb-2 mr-sm-2">
								<div class="input-group-prepend">
									<div class="input-group-text bg-white"><i class="fa fa-key text-primary"></i></div>
								</div>
								<input type="password" v-model="recent_password" class="form-control pr-5" id="inlineFormInputGroupKataSandi" placeholder="Kata Sandi Saat Ini" required>
							</div>
							<span toggle="#inlineFormInputGroupKataSandi" @click="togglePassword" class="fa fa-lg fa-eye toggle-password text-primary"></span>
							
							<label for="inlineFormInputGroupKataSandi" class="text-muted mb-1 mt-2">Kata Sandi Baru</label>
							<div class="input-group mb-2 mr-sm-2">
								<div class="input-group-prepend">
									<div class="input-group-text bg-white"><i class="fa fa-unlock-alt text-primary"></i></div>
								</div>
								<input type="password" v-model="new_password" class="form-control pr-5" placeholder="Kata Sandi Baru" required>
							</div>
							
							<label for="inlineFormInputGroupKataSandi" class="text-muted mb-1 mt-2">Konfirmasi Kata Sandi Baru</label>
							<div class="input-group mb-2 mr-sm-2">
								<div class="input-group-prepend">
									<div class="input-group-text bg-white"><i class="fa fa-unlock-alt text-primary"></i></div>
								</div>
								<input type="password" v-model="conf_new_password" class="form-control pr-5" placeholder="Konfirmasi Kata Sandi Baru" required>
							</div>
							<div class="form-group text-center mb-0 mt-4">
								<button type="submit" class="btn btn-primary">Ubah Kata Sandi &nbsp; <i class="fa fa-paper-plane"></i></button>
							</div>
						</form>
					</div>
					<!-- <div class="tab-pane fade px-3 py-2" id="bank-account" role="tabpanel">
						<div class="table-responsive">
							<div class="text-center my-3" v-if="isRefresh">
    						  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    						</div>
							<div v-else>
								<table class="table table-striped mb-0" v-if="rekening.length > 0">
									<tr class="border-bottom d-flex align-items-center" v-for="(rek, index) in rekening" :key="rek.mem_rek_id">
										<td class="border-top-0 border-bottom-0 border-left-0 border-right border-primary" v-if="index == 0">
											<h5 class="text-primary">{{ ++index }}</h5>
										</td>
										<td class="border-top-0 border-bottom-0 border-left-0 border-right" v-else>
											<h5 class="text-muted">{{ ++index }}</h5>
										</td>
										<td class="border-0" width="70%">
											<h6>{{ rek.mem_rek_label | capitalize }}</h6>
											<p class="mb-0 ml-3">{{ rek.mem_rek_no }} a.n. {{ rek.mem_rek_name }}</p>
											<p class="mb-0 ml-3">{{ rek.mem_rek_bank }}</p>
										</td>
										<td class="border-0" width="25%">
											<button type="button" class="btn btn-outline-warning m-1" data-toggle="modal" :data-target="'#update-rekening-modal-'+ rek.mem_rek_id"><i class="fa fa-edit"></i></button>

											<div class="modal fade" :id="'update-rekening-modal-'+ rek.mem_rek_id" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
												<div class="modal-dialog modal-dialog-centered" role="document">
													<div class="modal-content">
														<div class="modal-header">
															<h5 class="modal-title" id="exampleModalLongTitle">Ubah Rekening</h5>
															<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
														</div>
														<form ref="formUpdateRekening" class="px-3 py-2 text-left" @submit.prevent="updateRekening(rek.mem_rek_id, --index)">
															<div class="modal-body">
																<label class="text-muted mb-1">Label Rekening</label>
																<div class="input-group mb-2 mr-sm-2">
																	<div class="input-group-prepend">
																		<div class="input-group-text bg-white"><i class="far fa-address-card"></i></div>
																	</div>
																	<input type="text" class="form-control pr-5" placeholder="Label Rekening" name="rek_label" :value="rek.mem_rek_label" required>
																</div>
																<label class="text-muted mb-1">Nomor Rekening</label>
																<div class="input-group mb-2 mr-sm-2">
																	<div class="input-group-prepend">
																		<div class="input-group-text bg-white"><i class="fa fa-money-check-alt"></i></div>
																	</div>
																	<input type="number" class="form-control pr-5" placeholder="Nomor Rekening" name="rek_no" :value="rek.mem_rek_no" required>
																</div>
																<label class="text-muted mb-1">Atas Nama</label>
																<div class="input-group mb-2 mr-sm-2">
																	<div class="input-group-prepend">
																		<div class="input-group-text bg-white"><i class="fa fa-user"></i></div>
																	</div>
																	<input type="text" class="form-control pr-5" placeholder="Atas Nama" name="rek_name" :value="rek.mem_rek_name" required>
																</div>
																<label class="text-muted mb-1">Nama Bank</label>
																<div class="input-group mb-2 mr-sm-2">
																	<div class="input-group-prepend">
																		<div class="input-group-text bg-white"><i class="fa fa-university"></i></div>
																	</div>
																	<input type="text" class="form-control pr-5" placeholder="Nama Bank" name="rek_bank" :value="rek.mem_rek_bank" required>
																</div>
																<label class="text-muted mb-1">Nama Cabang (Bank)</label>
																<div class="input-group mb-2 mr-sm-2">
																	<div class="input-group-prepend">
																		<div class="input-group-text bg-white"><i class="fa fa-university"></i></div>
																	</div>
																	<input type="text" class="form-control pr-5" placeholder="Nama Bank" name="rek_cabang" :value="rek.mem_rek_cabang" required>
																</div>
															</div>
															<div class="modal-footer">
																<button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
																<button type="submit" class="btn btn-primary">Simpan &nbsp; <i class="fa fa-paper-plane"></i></button>
															</div>
														</form>
													</div>
												</div>
											</div>

											<button type="button" class="btn btn-outline-danger m-1" @click="deleteRekening(rek.mem_rek_id, rek.mem_rek_label)"><i class="fa fa-trash"></i></button>
										</td>
									</tr>
								</table>
								<div class="text-center w-100 px-3 pt-3 pb-0" v-else>
									<h5 class="text-info mb-0">Oops!</h5>
									<p class="text-muted mb-0">Belum ada rekening yang tercatat</p>
								</div>
							</div>
						</div>
						<div class="form-group text-center mb-0 mt-4">

							<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#tambah-rekening-modal">Tambah Rekening &nbsp; <i class="fa fa-plus-circle"></i></button>
							
							<div class="modal fade" id="tambah-rekening-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
								<div class="modal-dialog modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<h5 class="modal-title" id="exampleModalLongTitle">Tambah Rekening</h5>
											<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
										</div>
										<form ref="formAddRekening" class="px-3 py-2 text-left" @submit.prevent="addRekening">
											<div class="modal-body">
												<label class="text-muted mb-1">Label Rekening</label>
												<div class="input-group mb-2 mr-sm-2">
													<div class="input-group-prepend">
														<div class="input-group-text bg-white"><i class="far fa-address-card"></i></div>
													</div>
													<input type="text" class="form-control pr-5" placeholder="Label Rekening" name="rek_label" required>
												</div>
												<label class="text-muted mb-1">Nomor Rekening</label>
												<div class="input-group mb-2 mr-sm-2">
													<div class="input-group-prepend">
														<div class="input-group-text bg-white"><i class="fa fa-money-check-alt"></i></div>
													</div>
													<input type="number" class="form-control pr-5" placeholder="Nomor Rekening" name="rek_no" required>
												</div>
												<label class="text-muted mb-1">Atas Nama</label>
												<div class="input-group mb-2 mr-sm-2">
													<div class="input-group-prepend">
														<div class="input-group-text bg-white"><i class="fa fa-user"></i></div>
													</div>
													<input type="text" class="form-control pr-5" placeholder="Atas Nama" name="rek_name" required>
												</div>
												<label class="text-muted mb-1">Nama Bank</label>
												<div class="input-group mb-2 mr-sm-2">
													<div class="input-group-prepend">
														<div class="input-group-text bg-white"><i class="fa fa-university"></i></div>
													</div>
													<input type="text" class="form-control pr-5" placeholder="Nama Bank" name="rek_bank" required>
												</div>
												<label class="text-muted mb-1">Nama Cabang (Bank)</label>
												<div class="input-group mb-2 mr-sm-2">
													<div class="input-group-prepend">
														<div class="input-group-text bg-white"><i class="fa fa-university"></i></div>
													</div>
													<input type="text" class="form-control pr-5" placeholder="Nama Bank" name="rek_cabang" required>
												</div>
											</div>
											<div class="modal-footer">
												<button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
												<button type="submit" class="btn btn-primary">Simpan &nbsp; <i class="fa fa-paper-plane"></i></button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div> -->
					<div class="tab-pane fade px-3 py-2" id="member-company" role="tabpanel">
						<div class="text-center my-3" v-if="isRefresh">
    					  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    					</div>
						<div v-else>
							<div class="table-responsive" v-if="company">
								<table class="table table-borderless table-striped mb-0">
									<tr>
										<td width="30%">Nama Perusahaan</td>
										<td>:</td>
										<td v-if="company.com_name">{{ company.com_name | capitalize }}</td>
										<td v-else>-</td>
									</tr>
									<tr>
										<td width="30%">Alamat</td>
										<td>:</td>
										<td v-if="company.com_address">{{ company.com_address }}</td>
										<td v-else>-</td>
									</tr>
									<tr>
										<td width="30%">Email</td>
										<td>:</td>
										<td v-if="company.com_telp">{{ company.com_telp }}</td>
										<td v-else>-</td>
									</tr>
									<tr>
										<td width="30%">Telepon</td>
										<td>:</td>
										<td v-if="company.com_telp">{{ company.com_telp }}</td>
										<td v-else>-</td>
									</tr>
									<tr>
										<td width="30%">No. NPWP</td>
										<td>:</td>
										<td v-if="company.com_npwp">{{ company.com_npwp }}</td>
										<td v-else>-</td>
									</tr>
									<tr>
										<td width="30%">Badan Usaha</td>
										<td>:</td>
										<td v-if="company.com_badan_usaha">{{ company.com_badan_usaha }}</td>
										<td v-else>-</td>
									</tr>
									<tr>
										<td width="30%">Klasifikasi Badan Usaha</td>
										<td>:</td>
										<td v-if="company.com_class_badan_usaha">{{ company.com_class_badan_usaha }}</td>
										<td v-else>-</td>
									</tr>
								</table>

								<div class="form-group text-center mb-0 mt-4">
									<!-- Button trigger modal -->														
									<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#simpan-perusahaan-modal">Ubah Data Perusahaan &nbsp; <i class="fa fa-edit"></i></button>
										
									<!-- Modal -->
									<div class="modal fade" id="simpan-perusahaan-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
										<div class="modal-dialog modal-dialog-centered" role="document">
											<div class="modal-content">
												<div class="modal-header">
													<h5 class="modal-title" id="exampleModalLongTitle">Ubah Data Perusahaan</h5>
													<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
												</div>
												<form ref="formUpdateCompany" class="px-3 py-2 text-left" @submit.prevent="updateCompany" enctype="multipart/form-data">
													<div class="modal-body">
														<label class="text-muted mb-1">Nama Perusahaan</label>
														<div class="input-group mb-2 mr-sm-2">
															<div class="input-group-prepend">
																<div class="input-group-text bg-white"><i class="far fa-building"></i></div>
															</div>
															<input type="text" name="com_name" class="form-control pr-5" :value="company.com_name" placeholder="Nama Perusahaan" required>
														</div>

														<label class="text-muted mb-1">Alamat Perusahaan</label>
														<div class="input-group mb-2 mr-sm-2">
															<div class="input-group-prepend">
																<div class="input-group-text bg-white"><i class="fa fa-map-marker-alt"></i></div>
															</div>
															<textarea class="form-control pr-5" placeholder="Nama Jalan, No. Rumah, No. Kompleks, Nama Gedung, Lantai atau No. Unit, RT/RW" name="com_address" :value="company.com_address" required></textarea>
														</div>
														
														<label class="text-muted mb-1">Telepon Perusahaan</label>
														<div class="input-group mb-2 mr-sm-2">
															<div class="input-group-prepend">
																<div class="input-group-text bg-white"><i class="fa fa-phone-alt"></i></div>
															</div>
															<input type="number" name="com_telp" class="form-control pr-5" placeholder="Telepon Perusahaan" :value="company.com_telp" required>
														</div>
														
														<label class="text-muted mb-1">Email Perusahaan</label>
														<div class="input-group mb-2 mr-sm-2">
															<div class="input-group-prepend">
																<div class="input-group-text bg-white"><i class="fa fa-envelope"></i></div>
															</div>
															<input type="email" name="com_email" class="form-control pr-5" placeholder="Email Perusahaan" :value="company.com_email" required>
														</div>

														<label class="text-muted mb-1">Badan Usaha</label>
														<div class="input-group mb-2 mr-sm-2">
															<div class="input-group-prepend">
																<div class="input-group-text bg-white"><i class="fa fa-building"></i></div>
															</div>
															<select class="custom-select" name="com_badan_usaha" required>
																<option value="PT" v-if="company.com_badan_usaha == 'PT'" selected>PT</option>
																<option value="PT" v-else>PT</option>
																<option value="CV" v-if="company.com_badan_usaha == 'CV'" selected>CV</option>
																<option value="CV" v-else>CV</option>
																<option value="UD" v-if="company.com_badan_usaha == 'UD'" selected>UD</option>
																<option value="UD" v-else>UD</option>
																<option value="Firma" v-if="company.com_badan_usaha == 'Firma'" selected>Firma</option>
																<option value="Firma" v-else>Firma</option>
																<option value="Koperasi" v-if="company.com_badan_usaha == 'Koperasi'" selected>Koperasi</option>
																<option value="Koperasi" v-else>Koperasi</option>
																<option value="Yayasan" v-if="company.com_badan_usaha == 'Yayasan'" selected>Yayasan</option>
																<option value="Yayasan" v-else>Yayasan</option>
																<option value="Lembaga" v-if="company.com_badan_usaha == 'Lembaga'" selected>Lembaga</option>
																<option value="Lembaga" v-else>Lembaga</option>
																<option value="Institusi Pemerintah" v-if="company.com_badan_usaha == 'Institusi Pemerintah'" selected>Institusi Pemerintah</option>
																<option value="Institusi Pemerintah" v-else>Institusi Pemerintah</option>
																<option value="BUMN" v-if="company.com_badan_usaha == 'BUMN'" selected>BUMN</option>
																<option value="BUMN" v-else>BUMN</option>
															</select>
														</div>
														
														<label class="text-muted mb-1">Klasifkasi Badan Usaha</label>
														<div class="input-group mb-2 mr-sm-2">
															<div class="input-group-prepend">
																<div class="input-group-text bg-white"><i class="fa fa-building"></i></div>
															</div>
															<select class="custom-select" name="com_class_badan_usaha" required>
																<option value="Kontraktor / Konstruksi" v-if="company.com_class_badan_usaha == 'Kontraktor / Konstruksi'" selected>Kontraktor / Konstruksi</option>
																<option value="Kontraktor / Konstruksi" v-else>Kontraktor / Konstruksi</option>
																<option value="Jasa Instalasi" v-if="company.com_class_badan_usaha == 'Jasa Instalasi'" selected>Jasa Instalasi</option>
																<option value="Jasa Instalasi" v-else>Jasa Instalasi</option>
																<option value="Teknologi Informasi / Komunikasi" v-if="company.com_class_badan_usaha == 'Teknologi Informasi / Komunikasi'" selected>Teknologi Informasi / Komunikasi</option>
																<option value="Teknologi Informasi / Komunikasi" v-else>Teknologi Informasi / Komunikasi</option>
																<option value="Industri / Manufaktur" v-if="company.com_class_badan_usaha == 'Industri / Manufaktur'" selected>Industri / Manufaktur</option>
																<option value="Industri / Manufaktur" v-else>Industri  / Manufaktur</option>
																<option value="Properti / Building Management" v-if="company.com_class_badan_usaha == 'Properti / Building Management'" selected>Properti / Building Management</option>
																<option value="Properti / Building Management" v-else>Properti / Building Management</option>
																<option value="Keuangan / Asuransi / Multifinance / Perbankan" v-if="company.com_class_badan_usaha == 'Keuangan / Asuransi / Multifinance / Perbankan'" selected>Keuangan / Asuransi / Multifinance / Perbankan</option>
																<option value="Keuangan / Asuransi / Multifinance / Perbankan" v-else>Keuangan / Asuransi / Multifinance / Perbankan</option>
																<option value="Kesehatan" v-if="company.com_class_badan_usaha == 'Kesehatan'" selected>Kesehatan</option>
																<option value="Kesehatan" v-else>Kesehatan</option>
																<option value="Industri Kecil" v-if="company.com_class_badan_usaha == 'Industri Kecil'" selected>Industri Kecil</option>
																<option value="Industri Kecil" v-else>Industri Kecil</option>
																<option value="Lain lain" v-if="company.com_class_badan_usaha == 'Lain lain'" selected>Lain lain</option>
																<option value="Lain lain" v-else>Lain lain</option>
															</select>
														</div>

														<label class="text-muted mb-1">Nomor NPWP</label>
														<div class="input-group mb-2 mr-sm-2">
															<div class="input-group-prepend">
																<div class="input-group-text bg-white"><i class="fa fa-address-card"></i></div>
															</div>
															<input type="text" name="no_npwp" class="form-control pr-5" placeholder="Nomor NPWP Perusahaan" :value="company.com_npwp" required>
														</div>

														<label class="text-muted mt-1 mb-0">Bukti Gambar NPWP</label>
														<div class="form-group mb-0">
															<input type="file" name="npwp_img" class="form-control" style="padding: 3px 15px;">
															<small class="text-muted"><i class="fa fa-info-circle ml-2"></i> Kosongkan jika tidak ingin mengubah gambar.</small>
														</div>

														<label class="text-muted mt-2 mb-0">Bukti Gambar KTP Penanggung Jawab</label>
														<div class="form-group mb-0">
															<input type="file" name="identity_img" class="form-control" style="padding: 3px 15px;">
															<small class="text-muted"><i class="fa fa-info-circle ml-2"></i> Kosongkan jika tidak ingin mengubah gambar.</small>
														</div>
													</div>
													<div class="modal-footer">
														<button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
														<button type="submit" class="btn btn-primary">Simpan &nbsp; <i class="fa fa-paper-plane"></i></button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="text-center w-100 px-3 pt-3 pb-0" v-else>
								<h5 class="text-info mb-0">Oops!</h5>
								<p class="text-muted mb-0">Belum ada perusahaan yang tercatat</p>
								
								<div class="form-group text-center mb-0 mt-4">
									<!-- Button trigger modal -->														
									<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#buat-perusahaan-modal">Buat Data Perusahaan &nbsp; <i class="fa fa-plus-circle"></i></button>
										
									<!-- Modal -->
									<div class="modal fade" id="buat-perusahaan-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
										<div class="modal-dialog modal-dialog-centered" role="document">
											<div class="modal-content">
												<div class="modal-header">
													<h5 class="modal-title" id="exampleModalLongTitle">Buat Data Perusahaan</h5>
													<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
												</div>
												<form ref="formAddCompany" class="px-3 py-2 text-left" @submit.prevent="addCompany" enctype="multipart/form-data">
													<div class="modal-body">
														<label class="text-muted mb-1">Nama Perusahaan</label>
														<div class="input-group mb-2 mr-sm-2">
															<div class="input-group-prepend">
																<div class="input-group-text bg-white"><i class="far fa-building"></i></div>
															</div>
															<input type="text" name="com_name" class="form-control pr-5" placeholder="Nama Perusahaan" required>
														</div>

														<label class="text-muted mb-1">Alamat Perusahaan</label>
														<div class="input-group mb-2 mr-sm-2">
															<div class="input-group-prepend">
																<div class="input-group-text bg-white"><i class="fa fa-map-marker-alt"></i></div>
															</div>
															<textarea class="form-control pr-5" placeholder="Nama Jalan, No. Rumah, No. Kompleks, Nama Gedung, Lantai atau No. Unit, RT/RW" name="com_address" required></textarea>
														</div>
														
														<label class="text-muted mb-1">Telepon Perusahaan</label>
														<div class="input-group mb-2 mr-sm-2">
															<div class="input-group-prepend">
																<div class="input-group-text bg-white"><i class="fa fa-phone-alt"></i></div>
															</div>
															<input type="number" name="com_telp" class="form-control pr-5" placeholder="Telepon Perusahaan" required>
														</div>
														
														<label class="text-muted mb-1">Email Perusahaan</label>
														<div class="input-group mb-2 mr-sm-2">
															<div class="input-group-prepend">
																<div class="input-group-text bg-white"><i class="fa fa-envelope"></i></div>
															</div>
															<input type="email" name="com_email" class="form-control pr-5" placeholder="Email Perusahaan" required>
														</div>

														<label class="text-muted mb-1">Badan Usaha</label>
														<div class="input-group mb-2 mr-sm-2">
															<div class="input-group-prepend">
																<div class="input-group-text bg-white"><i class="fa fa-building"></i></div>
															</div>
															<select class="custom-select" name="com_badan_usaha" required>
																<option value="" selected disabled>Pilih Badan Usaha</option>
																<option value="PT">PT</option>
																<option value="CV">CV</option>
																<option value="UD">UD</option>
																<option value="Firma">Firma</option>
																<option value="Koperasi">Koperasi</option>
																<option value="Yayasan">Yayasan</option>
																<option value="Lembaga">Lembaga</option>
																<option value="Institusi Pemerintah">Institusi Pemerintah</option>
																<option value="BUMN">BUMN</option>
															</select>
														</div>
														
														<label class="text-muted mb-1">Klasifkasi Badan Usaha</label>
														<div class="input-group mb-2 mr-sm-2">
															<div class="input-group-prepend">
																<div class="input-group-text bg-white"><i class="fa fa-building"></i></div>
															</div>
															<select class="custom-select" name="com_class_badan_usaha" required>
																<option value="" selected disabled>Pilih Klasifkasi Badan Usaha</option>
																<option value="Kontraktor / Konstruksi">Kontraktor / Konstruksi</option>
																<option value="Jasa Instalasi">Jasa Instalasi</option>
																<option value="Teknologi Informasi / Komunikasi">Teknologi Informasi / Komunikasi</option>
																<option value="Industri / Manufaktur">Industri / Manufaktur</option>
																<option value="Properti / Building Management">Properti / Building Management</option>
																<option value="Keuangan / Asuransi / Multifinance / Perbankan">Keuangan / Asuransi / Multifinance / Perbankan</option>
																<option value="Kesehatan">Kesehatan</option>
																<option value="Industri Kecil">Industri Kecil</option>
																<option value="Lain lain">Lain lain</option>
															</select>
														</div>
														<label class="text-muted mb-1">Nomor NPWP</label>
														<div class="input-group mb-2 mr-sm-2">
															<div class="input-group-prepend">
																<div class="input-group-text bg-white"><i class="fa fa-address-card"></i></div>
															</div>
															<input type="text" name="no_npwp" class="form-control pr-5" placeholder="Nomor NPWP Perusahaan" required>
														</div>

														<label class="text-muted mt-1 mb-0">Bukti Gambar NPWP</label>
														<div class="form-group mb-0">
															<input type="file" name="npwp_img" class="form-control" style="padding: 3px 15px;" required>
														</div>

														<label class="text-muted mt-2 mb-0">Bukti Gambar KTP Penanggung Jawab</label>
														<div class="form-group mb-0">
															<input type="file" name="identity_img" class="form-control" style="padding: 3px 15px;" required>
														</div>
													</div>
													<div class="modal-footer">
														<button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
														<button type="submit" class="btn btn-primary">Simpan &nbsp; <i class="fa fa-paper-plane"></i></button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'

export default {
	name: 'MenuProfile',
	data() {
		return {
			transaksi: '',
			is_refreshDetail: false,
			detail_barang_transaksi: [],
			detail_info_transaksi: [],
			status_transaksi: '',
			profile: '',
			photo_profile: '',
			image_show: '',
			first_preview: false,
			province: '',
			city: '',
			kecamatan: '',
			province_edit: '',
			city_edit: '',
			kecamatan_edit: '',
			address: '',
			rekening: '',
			isRefresh: false,
			is_loadCity: false,
			is_loadKec: false,
			recent_password: '',
			new_password: '',
			company: '',
		}
	},
	methods: {
		to_topPage() {
			$('html, body').stop().animate({
				scrollTop: 83
			}, 1000, 'easeInOutExpo')
		},
		togglePassword: function() {
			$('span.toggle-password').toggleClass("fa-eye fa-eye-slash");
			let input = $($('span.toggle-password').attr("toggle"));
			if (input.attr("type") == "password") {
				input.attr("type", "text");
			}
			else {
				input.attr("type", "password");
			}
		},
		// get_transactionList() {
		// 	this.isRefresh = true
		// 	this.transaksi = []
		// 	window.axios.get('/transaction?status=5', {
       	// 	        headers: {
       	// 	            "token": this.$store.getters.token_member
       	// 	        }
       	// 	    }
       	// 	)
       	// 	.then(response => {
		// 		let jumlah = response.data.data.length
		// 		for(let i=0; i<jumlah; i++) {
		// 			this.transaksi.push(response.data.data[i])
		// 		}
       	// 	})
       	// 	.catch(error => {
       	// 		console.log(error)
		// 	});
		// 	window.axios.get('/transaction?status=6', {
       	// 	        headers: {
       	// 	            "token": this.$store.getters.token_member
       	// 	        }
       	// 	    }
       	// 	)
       	// 	.then(response => {
		// 		let jumlah = response.data.data.length
		// 		for(let i=0; i<jumlah; i++) {
		// 			this.transaksi.push(response.data.data[i])
		// 		}
       	// 	})
       	// 	.catch(error => {
       	// 		console.log(error)
		// 	});
		// 	this.isRefresh = false
		// },
		// get_detailTransaction(id, status) {
		// 	this.is_refreshDetail = true
		// 	window.axios.get('/transaction/'+ id, {
		// 			headers: {
		// 				"token": this.$store.getters.token_member
		// 			}
		// 		}
		// 	)
		// 	.then(response => {
		// 		this.detail_barang_transaksi = response.data.data.detail_transaction
		// 		this.detail_info_transaksi = response.data.data.transaction
		// 		this.status_transaksi = status
		// 		this.is_refreshDetail = false
		// 	})
		// 	.catch(error => {
		// 		console.log(error)
		// 	});
		// },
		getProfile() {
			this.isRefresh = true
			window.axios.get('/me/profile', {
       		        headers: {
       		            "token": this.$store.getters.token_member
       		        }
       		    }
       		)
       		.then(response => {
				this.profile = response.data
				
				localStorage.setItem('photo_profile', response.data.photo)
				localStorage.setItem('account_type', response.data.account_type)
				
				window.NavbarDirtyAccess.get_userComponentData()
				window.MenuMemberDirtyAccess.get_userComponentData()
				
				this.image_show = response.data.photo
				this.first_preview = true
				this.isRefresh = false
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		onFileChange(e) {
			this.photo_profile = e.target.files[0]
			let files = e.target.files || e.dataTransfer.files
			if (!files.length) {
				return;
			}
			this.createImage(files[0]);
		},
		createImage(file) {
			let reader = new FileReader()

			reader.onload = (e) => {
				this.first_preview = false
				this.image_show = e.target.result
			}
			reader.readAsDataURL(file)
		},
		removeImage: function (e) {
			this.image_show = ''
			this.first_preview = true
		},
		updateProfile: function () {
			this.isRefresh = true
			$('#ubah-data-diri-modal').modal('hide');

			let formUpdateProfile = this.$refs.formUpdateProfile
			let formData = new FormData(formUpdateProfile)
			formData.append('_method', 'patch')
			formData.append('photo_profile', this.photo_profile)
			
			this.$store.dispatch('updateProfile', formData)
       		.then((response) => {
				this.$swal({
					title: 'Berhasil', 
					html: '<p>Data diri berhasil diubah</p>',
					icon: 'success',
					timer: 2500,
					showConfirmButton: false,
				});
				
				this.profile = response.data
				
				window.NavbarDirtyAccess.get_userComponentData()
				window.MenuMemberDirtyAccess.get_userComponentData()
				this.isRefresh = false
			})
			.catch(error => console.log(error))
		},
		getProvince() {
			window.axios.get('/province')
       		.then(response => {
				this.province = response.data.data
				this.province_edit = response.data.data
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		prepare_changeAddress(id, prov_id, city_id) {
			this.is_refreshDetail = true
			this.is_loadCity = true
			this.is_loadKec = true
			
			window.axios.get('/city?province_id='+ prov_id)
       		.then(response => {
				this.city_edit = response.data.data
				this.is_loadCity = false
       		})
       		.catch(error => {
       			console.log(error)
			});
			window.axios.get('/subdistrict?city_id='+ city_id)
       		.then(response => {
				this.kecamatan_edit = response.data.data
				this.is_loadKec = false
       		})
       		.catch(error => {
       			console.log(error)
			});
			
			this.is_refreshDetail = false
			$('#update-alamat-modal-'+ id).modal()
		},
		on_changeProvince(event, act) {
			this.is_loadCity = true
			
			window.axios.get('/city?province_id='+ event.target.value)
       		.then(response => {
				if(act == 1) {
					this.city = response.data.data
				}
				else {
					this.city_edit = response.data.data
				}
				this.is_loadCity = false
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		on_changeCity(event, act) {
			this.is_loadKec = true
			window.axios.get('/subdistrict?city_id='+ event.target.value)
       		.then(response => {
				if(act == 1) {
					this.kecamatan = response.data.data
				}
				else {
					this.kecamatan_edit = response.data.data
				}
				this.is_loadKec = false
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		getAddress() {
			this.isRefresh = true
			window.axios.get('/me/profile/address', {
       		        headers: {
       		            "token": this.$store.getters.token_member
       		        }
       		    }
       		)
       		.then(response => {
				this.address = response.data.data
				this.isRefresh = false
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		addAddress: function () {
			$('#tambah-alamat-modal').modal('hide');

			let formAddAddress = this.$refs.formAddAddress
			let formData = new FormData(formAddAddress)

			window.axios.post('/me/profile/address', formData, {
       		        headers: {
						"Content-Type": "application/x-www-form-urlencoded",
       		            "token": this.$store.getters.token_member
       		        }
       		    }
       		)
       		.then(response => {
				this.$swal({
					title: 'Berhasil', 
					html: '<p>Data alamat berhasil dibuat</p>',
					icon: 'success',
					timer: 2500,
					showConfirmButton: false,
				});
				this.getAddress()
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		updateAddress: function (id, index) {
			$('#update-alamat-modal-'+ id).modal('hide');

			let formUpdateAddress = this.$refs.formUpdateAddress[index]
			let formData = new FormData(formUpdateAddress)
			formData.append('_method', 'patch')

			window.axios.post('/me/profile/address/'+ id, formData, {
       		        headers: {
						"Content-Type": "application/x-www-form-urlencoded",
       		            "token": this.$store.getters.token_member
       		        }
       		    }
       		)
       		.then(response => {
				this.$swal({
					title: 'Berhasil', 
					html: '<p>Data alamat berhasil diubah</p>',
					icon: 'success',
					timer: 2500,
					showConfirmButton: false,
				});
				this.getAddress()
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		deleteAddress: function(id, name) {
			this.$swal({
				title: 'Yakin?', 
				html: '<p>akan menghapus alamat '+ name +'</p>',
				icon: 'warning',
				timer: 7000,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, hapus!'
			})
			.then((result) => {
				if (result.isConfirmed) {
					window.axios.delete('/me/profile/address/'+ id, {
							headers: {
								"token": this.$store.getters.token_member
							}
						}
					)
					.then((response) => {
						this.$swal({
							title: 'Berhasil', 
							html: '<p>Data alamat berhasil dihapus</p>',
							icon: 'success',
							timer: 2500,
							showConfirmButton: false,
						});
						this.getAddress()
					})
					.catch((error) => {
						console.log(error)
					})
				}
			});
		},
		changePassword() {
			if(this.new_password === this.conf_new_password) {
				let formData = new FormData()
				formData.append('_method', 'patch')
				formData.append('recent_password', this.recent_password)
				formData.append('new_password', this.new_password)

				window.axios.post('/me/change-password', formData, {
						headers: {
							"Content-Type": "application/x-www-form-urlencoded",
							"token": this.$store.getters.token_member
						}
					}
				)
				.then(response => {
					if(response.data.success) {
						this.$swal({
							title: 'Berhasil', 
							html: '<p>Kata Sandi berhasil di ubah!</p>',
							icon: 'success',
							timer: 3000,
							showConfirmButton: false,
						});
					}
					else {
						this.$swal({
							title: 'Gagal', 
							html: '<p>Kata Sandi Saat Ini tidak sesuai!</p>',
							icon: 'error',
							timer: 3000,
							showConfirmButton: false,
						});
					}
				})
				.catch(error => {
					this.$swal({
						title: 'Gagal', 
						html: '<p>Kata Sandi Saat Ini tidak sesuai!</p>',
						icon: 'error',
						timer: 3000,
						showConfirmButton: false,
					});
				});
			}
			else {
				this.$swal({
					title: 'Oops!', 
					html: '<p>Konfirmasi Kata Sandi tidak sesuai!</p>',
					icon: 'error',
					timer: 3000,
					showConfirmButton: false,
				});
			}
			this.recent_password = ''
			this.new_password = ''
			this.conf_new_password = ''
		},
		// getRekening() {
		// 	this.isRefresh = true
		// 	window.axios.get('/me/profile/rekening', {
       	// 	        headers: {
       	// 	            "token": this.$store.getters.token_member
       	// 	        }
       	// 	    }
       	// 	)
       	// 	.then(response => {
		// 		this.rekening = response.data
		// 		this.isRefresh = false
       	// 	})
       	// 	.catch(error => {
       	// 		console.log(error)
		// 	});
		// },
		// addRekening: function () {
		// 	$('#tambah-rekening-modal').modal('hide');

		// 	let formAddRekening = this.$refs.formAddRekening
		// 	let formData = new FormData(formAddRekening)

		// 	window.axios.post('/me/profile/rekening', formData, {
       	// 	        headers: {
		// 				"Content-Type": "application/x-www-form-urlencoded",
       	// 	            "token": this.$store.getters.token_member
       	// 	        }
       	// 	    }
       	// 	)
       	// 	.then(response => {
		// 		this.$swal({
		// 			title: 'Berhasil', 
		// 			html: '<p>Data rekening berhasil dibuat</p>',
		// 			icon: 'success',
		// 			timer: 2500,
		// 			showConfirmButton: false,
		// 		});
		// 		this.getRekening()
       	// 	})
       	// 	.catch(error => {
       	// 		console.log(error)
		// 	});
		// },
		// updateRekening: function (id, index) {
		// 	$('#update-rekening-modal-'+ id).modal('hide');

		// 	let formUpdateRekening = this.$refs.formUpdateRekening[index]
		// 	let formData = new FormData(formUpdateRekening)
		// 	formData.append('_method', 'patch')

		// 	window.axios.post('/me/profile/rekening/'+ id, formData, {
       	// 	        headers: {
		// 				"Content-Type": "application/x-www-form-urlencoded",
       	// 	            "token": this.$store.getters.token_member
       	// 	        }
       	// 	    }
       	// 	)
       	// 	.then(response => {
		// 		this.$swal({
		// 			title: 'Berhasil', 
		// 			html: '<p>Data rekening berhasil diubah</p>',
		// 			icon: 'success',
		// 			timer: 2500,
		// 			showConfirmButton: false,
		// 		});
		// 		this.getRekening()
       	// 	})
       	// 	.catch(error => {
       	// 		console.log(error)
		// 	});
		// },
		// deleteRekening: function(id, name) {
		// 	this.$swal({
		// 		title: 'Yakin?', 
		// 		html: '<p>akan menghapus rekening '+ name +'</p>',
		// 		icon: 'warning',
		// 		timer: 7000,
		// 		showCancelButton: true,
		// 		confirmButtonColor: '#3085d6',
		// 		cancelButtonColor: '#d33',
		// 		confirmButtonText: 'Ya, hapus!'
		// 	})
		// 	.then((result) => {
		// 		if (result.isConfirmed) {
		// 			window.axios.delete('/me/profile/rekening/'+ id, {
		// 					headers: {
		// 						"token": this.$store.getters.token_member
		// 					}
		// 				}
		// 			)
		// 			.then((response) => {
		// 				this.$swal({
		// 					title: 'Berhasil', 
		// 					html: '<p>Data rekening berhasil dihapus</p>',
		// 					icon: 'success',
		// 					timer: 2500,
		// 					showConfirmButton: false,
		// 				});
		// 				this.getRekening()
		// 			})
		// 			.catch((error) => {
		// 				console.log(error)
		// 			})
		// 		}
		// 	});
		// },
		getCompany() {
			this.isRefresh = true
			window.axios.get('/me/profile/company', {
       		        headers: {
       		            "token": this.$store.getters.token_member
       		        }
       		    }
       		)
       		.then(response => {
				this.company = response.data.data
				this.isRefresh = false
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		addCompany() {
			$('#buat-perusahaan-modal').modal('hide');

			let formAddCompany = this.$refs.formAddCompany
			let formData = new FormData(formAddCompany)

			window.axios.post('/me/profile/company', formData, {
       		        headers: {
						"Content-Type": "multipart/form-data",
       		            "token": this.$store.getters.token_member
       		        }
       		    }
       		)
       		.then(response => {
				this.$swal({
					title: 'Berhasil', 
					html: '<p>Data perusahaan berhasil ditambahkan</p>',
					icon: 'success',
					timer: 2500,
					showConfirmButton: false,
				});
				this.getCompany()
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		updateCompany() {
			$('#simpan-perusahaan-modal').modal('hide');

			let formUpdateCompany = this.$refs.formUpdateCompany
			let formData = new FormData(formUpdateCompany)
			formData.append('_method', 'patch')

			window.axios.post('/me/profile/company', formData, {
       		        headers: {
						"Content-Type": "multipart/form-data",
       		            "token": this.$store.getters.token_member
       		        }
       		    }
       		)
       		.then(response => {
				this.$swal({
					title: 'Berhasil', 
					html: '<p>Data perusahaan berhasil diubah</p>',
					icon: 'success',
					timer: 2500,
					showConfirmButton: false,
				});
				this.getCompany()
       		})
       		.catch(error => {
       			console.log(error)
			});
		}
	},
	created() {
		$(document).scrollTop(0)
       	// this.get_transactionList()
       	this.getProfile()
       	this.getProvince()
       	this.getAddress()
		// this.getRekening()
		this.getCompany()
		
		Vue.nextTick(function(){
			// Javascript to enable link to tab
			let hash = location.hash.replace(/^#/, '');  // ^ means starting, meaning only match the first hash
			if (hash) {
				$('.nav-item a[href="#' + hash + '"]').tab('show');
			} 

			// Change hash for page-reload
			$('.nav-item a').on('shown.bs.tab', function (e) {
				window.location.hash = e.target.hash;
			})
    	});
	},
}

</script>